import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

import aguia from "./assets/itfast.png";
import { Label } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    textAlign: "center",
  },
  imgBox: {
    maxWidth: "80%",
    maxHeight: "80%",
    margin: "10px",
  },
  img: {
    height: "inherit",
    maxWidth: "inherit",
  },
  input: {
    display: "none",
  },
}));

function App() {
  const classes = useStyles();
  const [source, setSource] = useState("");
  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setSource(newUrl);
      }
    }
  };

  const [message, setMessage] = useState("");

  const [valCodBar, setValCodBar] = useState("");

  const test =
  "<ce><b><da><dl><l>BEM VINDO<l><l></dl></da><inv><eg>S123<l></eg></inv></b><da>Sua posicao na fila: 10<l><l></da>Conheca nossos produtos!<l>http://tectoy.com.br/<l><l>06/10/2023<l></ce><l><l><l><l><l>";

function bold(message: string) {
  return `<b>${message}</b>`;
}

function boldEsc(message: string) {
  const negrito = String.fromCharCode(0x1B) + String.fromCharCode(0x45) + String.fromCharCode(0x31);
  const deslNegrito = String.fromCharCode(0x1B) + String.fromCharCode(0x45) + String.fromCharCode(0x30);
  return `${negrito}${message}${deslNegrito}`;
}


function italic(message: string) {
  return `<i>${message}</i>`;
}

function alignRight(message: string) {
  const direita = String.fromCharCode(0x1B) + String.fromCharCode(0x61) + String.fromCharCode(0x32);
  const deslDireita = String.fromCharCode(0x1B) + String.fromCharCode(0x61) + String.fromCharCode(0x30);
  return `${direita}${message}\n${deslDireita}`;
}


function guiEsc() {
  const cmdCortar = "\n\n\n\n\n\n\n" + String.fromCharCode(0x1B) + String.fromCharCode(0x6D);
  return cmdCortar;
}

function skip() {
  return `<l>`;
}

function skipLines(value: number) {
  return skip().repeat(value);
}

function center(message: string) {
  const centro = String.fromCharCode(0x1B) + String.fromCharCode(0x61) + String.fromCharCode(0x31);
  const deslCentro = String.fromCharCode(0x1B) + String.fromCharCode(0x61) + String.fromCharCode(0x30);
  return `${centro}${message}\n${deslCentro}`;
}

function repeatCharacter(char: string) {
  return char.repeat(48);
}

function doubleHeight(message: string) {
  return `<da>${message}</da>`;
}

function fontExtra(message: string) {
  return `<xl>${message}</xl>`;
}

function insertSpace(value: number) {
  return " ".repeat(value);
}

function fontElit(message: string) {
  return `<fe>${message}</fe>`;
}


function gerarQrCode(texto) {
    let strBufferImpressao = [];
    
    let tamanhoModulo = String.fromCharCode(0x1D) + String.fromCharCode(0x28) + String.fromCharCode(0x6B) +
                        String.fromCharCode(0x03) + String.fromCharCode(0x00) + String.fromCharCode(0x31) +
                        String.fromCharCode(0x43) + String.fromCharCode(0x04);
    strBufferImpressao.push(tamanhoModulo);

    let nivelCorrecao = String.fromCharCode(0x1D) + String.fromCharCode(0x28) + String.fromCharCode(0x6B) +
                        String.fromCharCode(0x03) + String.fromCharCode(0x00) + String.fromCharCode(0x31) +
                        String.fromCharCode(0x45) + String.fromCharCode(0x31);
    strBufferImpressao.push(nivelCorrecao);

    let retMenosMais = maiorMenorByte(texto);
    let byteMenosMais = String.fromCharCode(0x1D) + String.fromCharCode(0x28) + String.fromCharCode(0x6B) +
                        String.fromCharCode(retMenosMais[1]) + String.fromCharCode(retMenosMais[0]) +
                        String.fromCharCode(0x31) + String.fromCharCode(0x50) + String.fromCharCode(0x30) +
                        texto;
    strBufferImpressao.push(byteMenosMais);

    let imprimeQrCode = String.fromCharCode(0x1D) + String.fromCharCode(0x28) + String.fromCharCode(0x6B) +
                        String.fromCharCode(0x03) + String.fromCharCode(0x00) + String.fromCharCode(0x31) +
                        String.fromCharCode(0x51) + String.fromCharCode(0x30);
    strBufferImpressao.push(imprimeQrCode);

    return strBufferImpressao.join("");
}

function maiorMenorByte(dados) {
    let retorno = [];

    // Calcula byte mais e menos significativos somando 2 ao tamanho da string pois também contamos os bytes da correção e largura do módulo

    // Calculo do byte mais significativo
    // Se o valor do cálculo for igual a 0, então colocamos o inteiro 0.
    // Se colocar como null direto dá erro na hora de fazer a concatenação das variáveis
    if (((dados.length + 3) >> 8) === 0) {
        retorno.push(0);
    } else {
        retorno.push(((dados.length + 3) >> 8));
    }

    // Calculo do byte menos significativo
    // Se o valor do cálculo for igual a 0, então colocamos o inteiro 0.
    // Se colocar como null direto dá erro na hora de fazer a concatenação das variáveis
    if (((dados.length + 3) & 0x00ff) === 0) {
        retorno.push(0);
    } else {
        retorno.push(((dados.length + 3) & 0x00ff));
    }
    return retorno;
}



function printerFromCharCode(example: string){

  console.log(example)
  const removedBraket = example.split("{{").map(value => {
    let test = value.split("}}");
    if(!isEmpty(test[0])){
      console.log("Antes: ", test[0])
      test[0] = String.fromCharCode(test[0]);
      console.log("Depois: ", test[0])
    }
    return test.join("")
  })
  // console.log(removedBraket.join(""))
  return removedBraket.join("")
}

function isEmpty(value) {
  return (value == null || (typeof value === "string" && value.trim().length === 0));
}


function printCheckout(store: any, cart: any, printer: any){
  let printResult = [];
  printResult.push(skipLines(2))
  printResult.push(center(bold(fontElit(store.name+ skip()))));
  printResult.push(center(("CNPJ: " + store.gov_id.CNPJ + skip())));
  printResult.push(repeatCharacter("-") + skip());
  

  printResult.push(center(("Relatório de consumo <l>")));
  printResult.push(center(bold(fontElit(`${cart.cart_title}: ${cart.name}<l>`))));
  const openedDate = new Date(cart.opened);
  printResult.push(center((`ABERTURA: ${openedDate.toLocaleString()}<l>`)));
  printResult.push(repeatCharacter("-") + skip());

  printResult.push("ITEM" + insertSpace(2))
  printResult.push("QTD" + insertSpace(2))
  printResult.push("DESCRICAO")
  printResult.push("TOTAL" + skip());
  printResult.push(repeatCharacter("-") + skip());

  // TODO parte de itens

  // até aqui
  printResult.push(repeatCharacter("-") + skip());
  printResult.push("PRODUTOS: R$ " + printer.items_value + skip());
  if(printer.service > 0){
    printResult.push(("GORJETA SUGERIDA: R$ " + printer.service) + skip());
  }
  if(cart.delivery_fee > 0){
    printResult.push(("TAXA DE ENTREGA: R$ " + cart.delivery_fee) + skip());
  }
  if(printer.discount > 0){
    printResult.push(("DESCONTOS: R$ " + printer.discount) + skip());
  }
  printResult.push(("PRODUTOS: R$") + skip());


}

function viewText(){
  alert(generatePrinterString());
}

function generatePrinterString(){
  let printResult = [];
  printResult.push("\n\n")
  printResult.push(center(boldEsc("Atena Teste CENTER")) + "\n")
  printResult.push(alignRight("Teste à direita") + "\n")
  printResult.push(repeatCharacter("-"))
  printResult.push("Total")
  printResult.push(alignRight("100,00") + "\n")
  printResult.push(center("CNPJ: 01.980.486/0001-34") + "\n")
  printResult.push(guiEsc())
  return printResult.join("")
}

function nfePrinter(){

  let printResult = [];
  printResult.push(skipLines(2))
  printResult.push(bold(center("Quintana Bar")) + skip())
  printResult.push(center("STAN EXPRESS LANCHONETE LTDA") + skip())
  printResult.push(center("Rua Alessandro Volta 151") + skip())
  printResult.push(center("CNPJ: 01.980.486/0001-34"))
  printResult.push(insertSpace(2))
  printResult.push(center("I.E.: 114.966.311.116") + skip())
  printResult.push(center("25/03/2023  16:30:06") + skip())
  printResult.push(center("Extrato No. 24491") + skip())
  printResult.push(center("CUPOM FISCAL ELETRONICO - NFCe") + skip())
  printResult.push(skipLines(2))
  printResult.push("CPF: ..-" + skip())

  printResult.push(repeatCharacter("-") + skip());
  // ITEM 1 
  printResult.push("ITEM")
  printResult.push(insertSpace(2))
  printResult.push("COD")
  printResult.push(insertSpace(2))
  printResult.push("QTD")
  printResult.push(insertSpace(3))
  printResult.push("P.UNIT")
  printResult.push(insertSpace(2))
  printResult.push("DESCRICAO")
  printResult.push(insertSpace(2))
  printResult.push(("TOTAL") + skip())
  printResult.push(repeatCharacter("-") + skip());

  printResult.push(createTagsItem("001", "270", "1 UN", "16,00", "SUCO de LIMAO", "16,00"))
  printResult.push(createTagsItem("002", "106", "1 UN", "9,00", "Coca Cola Zero", "9,00"))
  printResult.push(createTagsItem("003", "120", "1 UN", "16,00", "SUCO DE MELANCIA", "16,00"))
  printResult.push(createTagsItem("004", "2014", "2 UN", "15,00", "Couver Artistico", "30,00"))
  printResult.push(createTagsItem("005", "88", "2 UN", "89,00", "FEIJOADA SABADO", "178,00"))
  printResult.push(repeatCharacter("-") + skip());

  printResult.push("TOTAL ")
  printResult.push(("249,00") + skip())
  printResult.push(repeatCharacter("-") + skip());

  printResult.push("CARTAO DE DEBITO ")
  printResult.push(("249,00 ") + skip())
  
  printResult.push("Troco ")
  printResult.push(("0,00") + skip())
  printResult.push(skipLines(2))

  printResult.push(center("CHAVE DE ACESSO DA NOTA FISCAL ELETRONICA") + skip())
  printResult.push(skipLines(2))

  printResult.push(bold(center("0000 0000 0000 0000 0000 0000 0000 0000 0000 0000 0000")) + skip())
  printResult.push(skipLines(4))

  printResult.push(center("https://www.nfce.fazenda.sp.gov.br/consulta") + skip())
  printResult.push(center(gerarQrCode("https://www.nfce.fazenda.sp.gov.br/consulta")) + skip())
  printResult.push(skipLines(2))

  printResult.push(center("Conf. Lei 12.741/2012 Tributacao aprox. R$33.49 (13.45%) Federal e "+
  "R$44.82 (18.00%) Estadual - Fonte IBPT / Optante pelo regime de tributacao de 3.2%, previsto " +
  "Decreto n 51.597/2007 /") + skip())
  printResult.push(skipLines(2))

  printResult.push(bold(center("Mesa 222")) + skip())
  printResult.push(skipLines(2))
  printResult.push(bold(center("Plataforma ATENA.AI v3.1.160 | 9")) + skip())
  return printResult.join("");
}

function createTagsItem(index: string, code: string, qtd: string, priceUnit: string, desc: string, total: string){
  let printResult = [];
  printResult.push(index)
  printResult.push(insertSpace(2))
  printResult.push(code)
  printResult.push(insertSpace(2))
  printResult.push(qtd)
  printResult.push(insertSpace(3))
  printResult.push(priceUnit)
  printResult.push(insertSpace(2))
  printResult.push(desc)
  printResult.push(insertSpace(2))
  printResult.push(total + skip())
  return printResult.join("");
}


  function print() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/printer',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'imprimir', PARAM: `${message}<l>`}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }

  
  async function printAtenaEsc() {

    // let str = generatePrinterString();
    let example = `
{{13}}
{{10}}

{{27}}{{97}}{{49}}{{27}}{{33}}{{32}}Wabiz #55{{27}}{{33}}{{0}}{{27}}{{97}}{{48}}

COZINHA              Atendente: Integracao Wabiz
------------------------------------------------{{27}}{{33}}{{32}}{{27}}{{97}}{{48}}
QTD            DESCRICAO
{{27}}{{33}}{{0}}------------------------------------------------{{27}}{{33}}{{32}}{{27}}{{33}}{{32}}{{27}}{{97}}{{48}}
{{27}}{{97}}{{48}}1  Pizzas 8 Pedacos{{27}}{{33}}{{32}}{{27}}{{33}}{{32}}
  1/2 Bauru            
      OBS: sem cebola  
  1/2 Portuguesa       
      OBS: sem ovo     {{27}}{{33}}{{32}}{{27}}{{97}}{{48}}
{{27}}{{97}}{{48}}1  Pizzas 8 Pedacos{{27}}{{33}}{{32}}
  Bauru                {{27}}{{33}}{{32}}{{27}}{{33}}{{32}}{{27}}{{97}}{{48}}
{{27}}{{97}}{{48}}1  Pizzas 8 Pedacos{{27}}{{33}}{{32}}
  Portuguesa           {{27}}{{33}}{{32}}
{{27}}{{33}}{{0}}------------------------------------------------{{27}}{{33}}{{8}}
28/02/2024 17:00:13 | 3.87          PEDIDO: 0055
------------------------------------------------
CLIENTE: Thais Carvalho
FORMA DE PAGAMENTO: R$ 214.0 em DinheiroTroco para 250.0
ENDERECO: Rosalia grisi sandoval 74 - Centro - Jundiai
================================================



{{13}}
{{10}}


{{13}}
{{10}}


{{27}}{{105}}`

    let str = printerFromCharCode(example)
    console.log(str);
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/printer',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'imprimir', PARAM: `${str}`}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });

  }

  async function printAtenaEsc2() {

    // let str = generatePrinterString();
    let example = `
{{27}}{{64}}
{{27}}{{108}}{{12}} 
{{27}}{{81}}{{12}} 

{{13}}
{{10}}

{{27}}{{97}}{{1}}{{27}}{{33}}{{32}}Wabiz #55{{27}}{{33}}{{0}}{{27}}{{97}}{{0}}

COZINHA              Atendente: Integracao Wabiz
------------------------------------------------{{27}}{{33}}{{32}}{{27}}{{97}}{{0}}
QTD            DESCRICAO
{{27}}{{33}}{{0}}------------------------------------------------{{27}}{{33}}{{32}}{{27}}{{33}}{{32}}{{27}}{{97}}{{0}}
{{27}}{{97}}{{0}}1  Pizzas 8 Pedacos{{27}}{{33}}{{32}}{{27}}{{33}}{{32}}
  1/2 Bauru            
      OBS: sem cebola  
  1/2 Portuguesa       
      OBS: sem ovo     {{27}}{{33}}{{32}}{{27}}{{97}}{{0}}
{{27}}{{97}}{{0}}1  Pizzas 8 Pedacos{{27}}{{33}}{{32}}
  Bauru                {{27}}{{33}}{{32}}{{27}}{{33}}{{32}}{{27}}{{97}}{{0}}
{{27}}{{97}}{{0}}1  Pizzas 8 Pedacos{{27}}{{33}}{{32}}
  Portuguesa           {{27}}{{33}}{{32}}
{{27}}{{33}}{{0}}------------------------------------------------{{27}}{{33}}{{8}}
28/02/2024 17:00:13 | 3.87          PEDIDO: 0055
------------------------------------------------
CLIENTE: Thais Carvalho
FORMA DE PAGAMENTO: R$ 214.0 em DinheiroTroco para 250.0
ENDERECO: Rosalia grisi sandoval 74 - Centro - Jundiai
================================================



{{13}}
{{10}}


{{13}}
{{10}}


{{27}}{{105}}`
    
    let str = printerFromCharCode(example)
    console.log(str);
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/printer',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'imprimir', PARAM: `${str}`}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });

  }

  async function printAtenaTags() {

    let str = nfePrinter();

    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/printer',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'imprimir', PARAM: `${str}`}
    };
    
    await axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });

    gui();
  }

  function printAtena() {
      // Supondo que você tenha a sequência de bytes como uma string chamada 'byteString'
    let byteString = `Paù 0aHWFaHWFBurger KitchenHW F
    CNPJ: 03654119000176
    -------------------------------------------------
    aHW FRELATORIO DE CONSUMOHW Fa 
    aHWFComanda: 006HW F
    aHW FABERTURA: 9/12/2023 10:53:17
    -------------------------------------------------
    ITEM  QTD  DESCRICAO                        TOTAL
    -------------------------------------------------
    001   1 UN AGUA SAN PELLEGRINO              32,00
    -------------------------------------------------
                                   PRODUTOS: R$ 32,00
                            GORJETA SUGERIDA: R$ 3,20HWFHW FHWF
              TOTAL: R$ 35,20
    HW F
    -------------------------------------------------
    Dinheiro                                    35,20HWF
                 COMANDA PAGAHW F
    
    aNAO E DOCUMENTO FISCALa 
    =================================================HW E
    OPERADOR: Ana                 28/02/2024 10:57:47
    aHW FPlataforma_ATENA.AI 20240228t130053 | 28a 
    
    
    
    
    m    
    `;

    // Converter a string de bytes para um Uint8Array
    let byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i);
    }

    const arrayBuffer = byteArray.buffer

    let str = new TextDecoder().decode(arrayBuffer)

    console.log(str)
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/printer',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'imprimir', PARAM: `${str}`}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

  function printAtena2() {
      // Supondo que você tenha a sequência de bytes como uma string chamada 'byteString'
    let byteString = 'G1AbYQEd+SAwG2EBG0gbVwEbRhthARtIG1cBG0ZCdXJnZXIgS2l0Y2hlbhtIG1cAG0YKQ05QSjogMDM2NTQxMTkwMDAxNzYKLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQ0KG2EBG0gbVwAbRlJFTEFUT1JJTyBERSBDT05TVU1PG0gbVwAbRhthAAobYQEbSBtXARtGQ29tYW5kYTogMDA2G0gbVwAbRgobYQEbSBtXABtGQUJFUlRVUkE6IDkvMTIvMjAyMyAxMDo1MzoxNwotLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tCklURU0gIFFURCAgREVTQ1JJQ0FPICAgICAgICAgICAgICAgICAgICAgICAgVE9UQUwKLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQowMDEgICAxIFVOIEFHVUEgU0FOIFBFTExFR1JJTk8gICAgICAgICAgICAgIDMyLDAwCi0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0KICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIFBST0RVVE9TOiBSJCAzMiwwMAogICAgICAgICAgICAgICAgICAgICAgICBHT1JKRVRBIFNVR0VSSURBOiBSJCAzLDIwG0gbVwEbRhtIG1cAG0YbSBtXARtGCiAgICAgICAgICBUT1RBTDogUiQgMzUsMjAKG0gbVwAbRgotLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tCkRpbmhlaXJvICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgMzUsMjAbSBtXARtGCiAgICAgICAgICAgICBDT01BTkRBIFBBR0EbSBtXABtGCg0KG2EBTkFPIEUgRE9DVU1FTlRPIEZJU0NBTBthAAo9PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09G0gbVwAbRQpPUEVSQURPUjogQW5hICAgICAgICAgICAgICAgICAyNy8xMi8yMDIzIDExOjIyOjA5ChthARtIG1cAG0ZQbGF0YWZvcm1hX0FURU5BLkFJIDIwMjMxMjIydDE5MzAzOSB8IDI4G2EACgoKDQoNChttDQo=';

    // Converter a string de bytes para um Uint8Array
    let byteArray = base64ToArrayBuffer(byteString)

    // Agora você pode manipular os bytes em 'byteArray' sem perder dados
    console.log(byteArray);

    // Para converter o Uint8Array de volta para uma string, você pode fazer algo assim:
    let str = new TextDecoder().decode(byteArray);
    console.log(str)
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/printer',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'imprimir', PARAM: `${str}`}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }

  function printAtena3() {
      // Supondo que você tenha a sequência de bytes como uma string chamada 'byteString'
    let byteString = 'G1AbYQEd+SAwG2EBG0gbVwEbRhthARtIG1cBG0ZCdXJnZXIgS2l0Y2hlbhtIG1cAG0YKQ05QSjogMDM2NTQxMTkwMDAxNzYKLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQ0KG2EBG0gbVwAbRlJFTEFUT1JJTyBERSBDT05TVU1PG0gbVwAbRhthAAobYQEbSBtXARtGQ29tYW5kYTogMDA2G0gbVwAbRgobYQEbSBtXABtGQUJFUlRVUkE6IDkvMTIvMjAyMyAxMDo1MzoxNwotLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tCklURU0gIFFURCAgREVTQ1JJQ0FPICAgICAgICAgICAgICAgICAgICAgICAgVE9UQUwKLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQowMDEgICAxIFVOIEFHVUEgU0FOIFBFTExFR1JJTk8gICAgICAgICAgICAgIDMyLDAwCi0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0KICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIFBST0RVVE9TOiBSJCAzMiwwMAogICAgICAgICAgICAgICAgICAgICAgICBHT1JKRVRBIFNVR0VSSURBOiBSJCAzLDIwG0gbVwEbRhtIG1cAG0YbSBtXARtGCiAgICAgICAgICBUT1RBTDogUiQgMzUsMjAKG0gbVwAbRgotLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tCkRpbmhlaXJvICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgMzUsMjAbSBtXARtGCiAgICAgICAgICAgICBDT01BTkRBIFBBR0EbSBtXABtGCg0KG2EBTkFPIEUgRE9DVU1FTlRPIEZJU0NBTBthAAo9PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09G0gbVwAbRQpPUEVSQURPUjogQW5hICAgICAgICAgICAgICAgICAyNy8xMi8yMDIzIDExOjIyOjA5ChthARtIG1cAG0ZQbGF0YWZvcm1hX0FURU5BLkFJIDIwMjMxMjIydDE5MzAzOSB8IDI4G2EACgoKDQoNChttDQo=';

    // Converter a string de bytes para um Uint8Array
    let byteArray = base64ToArrayBuffer(byteString)

    // Agora você pode manipular os bytes em 'byteArray' sem perder dados
    console.log(byteArray);

    // Para converter o Uint8Array de volta para uma string, você pode fazer algo assim:
    let str = new TextDecoder().decode(byteArray);

    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/printer',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'imprimir', PARAM: `${byteArray}<l>`}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }

  function printPwd() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/printer',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'imprimir', PARAM: `<ce><b><da><dl><l>BEM VINDO<l><l></dl></da><inv><eg>S123<l></eg></inv></b><da>Sua posicao na fila: 10<l><l></da>Conheca nossos produtos!<l>http://tectoy.com.br/<l><l>06/10/2023<l></ce><l><l><l><l><l>`}
    };
    
    axios.request(options).then(function (response) {
      const options = {
        method: 'POST',
        url: 'http://127.0.0.1:5000/printer',
        headers: {'Content-Type': 'application/json'},
        data: {CMD: 'acionarGuilhotina', PARAM: ''}
      };
      
      axios.request(options).then(function (response) {
        console.log(response.data);
      }).catch(function (error) {
        console.error(error);
      });
    }).catch(function (error) {
      console.error(error);
    });
  }

  function gui() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/printer',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'acionarGuilhotina', PARAM: ''}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }
  function ligaLedVermelho() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/led',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'ligarLedStatus', PARAM: 'VERMELHO'}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }
  function ligaLedVerde() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/led',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'ligarLedStatus', PARAM: 'VERDE'}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }
  function ligaLedAzul() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/led',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'ligarLedStatus', PARAM: 'AZUL'}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }
  function ligaLedAmarelo() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/led',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'ligarLedStatus', PARAM: 'AMARELO'}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }
  function ligaLedBranco() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/led',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'ligarLedStatus', PARAM: 'BRANCO'}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }
  function ligaLedCiano() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/led',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'ligarLedStatus', PARAM: 'CIANO'}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }
  function ligaLedMagenta() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/led',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'ligarLedStatus', PARAM: 'MAGENTA'}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }
  function desligaLed() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/led',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'desligarLedStatus', PARAM: ''}
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }
  async function ligarScanner() {
    const options = {
      method: 'POST',
      url: 'http://127.0.0.1:5000/scanner',
      headers: {'Content-Type': 'application/json'},
      data: {CMD: 'ligarScanner', PARAM: ''}
    };
    try{
      const {data,status}= await axios.request(options)
      console.log(data)
      return data
    }catch(ex) {
      console.log(ex)
      return ''
    } 
  }

  async function recebeScanner() {
    const options = {
      method: 'GET',
      url: 'http://127.0.0.1:5000/scanner',
      headers: {'Content-Type': 'application/json'},
      data: {}
    };
    try{
      const {data,status}= await axios.request(options);
      return data;
  }
  catch(ex){
    console.error(ex);
    return '';
  }
  }

  async function lerScanner() {
    await ligarScanner();
    await new Promise (r=>setTimeout(r,1000));
    for (let i=0;i<10;i++){
      const valCodBar = await recebeScanner();
      console.log(valCodBar);
      setValCodBar(valCodBar);
      
      await new Promise (r=>setTimeout(r,3000));
      if (valCodBar!=='') i=10;
    }
  }

  

  return (
  <div className={classes.root}>
      <Grid container style={{
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          justifyContent: "center",
        }}>
        <Grid item>
          <img
            src={aguia}
            alt="Aguia"
            style={{ width: "50%", height: "50%" }}
          />
        </Grid>
     </Grid>
     <Grid container style={{ marginTop: 100}} spacing={1}>
        <Grid container style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
        <Grid item>
            <TextField
              id="txtEntrada"
              type=""
              name= "txtEntrada"
              label= "Texto impressão"
              variant= "outlined"
              onChange={(event) => setMessage(event.target.value)}
            />
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={print}>
              Imprimir
            </Button>
        </Grid>
        {/* <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={viewText}>
              View Text
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="secondary" onClick={printAtena}>
              Imprimir Atena
            </Button>
        </Grid> */}
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="secondary" onClick={printAtenaEsc}>
              Imprimir Atena Teste 1
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="secondary" onClick={printAtenaEsc2}>
              Imprimir Atena Teste 2
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={printPwd}>
              Imprimir cupom exemplo
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={gui}>
              Acionar Guilhotina
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={ligaLedAzul}>
              Ligar Led Azul
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={ligaLedVerde}>
            Ligar Led Verde
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={ligaLedVermelho}>
            Ligar Led Vermelho
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={ligaLedAmarelo}>
              Ligar Led Amarelo
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={ligaLedBranco}>
              Ligar Led Branco
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={ligaLedCiano}>
              Ligar Led Ciano
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={ligaLedMagenta}>
              Ligar Led Magenta
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={desligaLed}>
              Desligar Led
            </Button>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={lerScanner}>
              Ler Scanner
            </Button>
            {valCodBar}
        </Grid>
      </Grid>
   
      <Grid item xs={12} style={{ marginTop: 30 }}>
          <input
            accept="image/*"
            className={classes.input}
            id="icon-button-file"
            type="file"
            capture="environment"
            onChange={(e) => handleCapture(e.target)}
          />
          <label htmlFor="icon-button-file">
            <Button variant="contained" color="primary" component="span">
              Abrir Câmera
            </Button>
          </label>
          <h5>Imagem Camera</h5>
          {source && (
            <Box
              style={{
                margin: "auto",
              }}
              border={1}
              className={classes.imgBox}
            >
              <img src={source} alt={"snap"} className={classes.img}></img>
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default App;